<template>
  <v-dialog v-model="value" persistent max-width="850px">
    <v-card v-if="user.profileChanges">
      <v-card-title>
        <span class="text-h5">{{ $t("users.dialogs.profileChanges.title") }}: {{ user.name }}
          {{ user.surname }}
        </span>
      </v-card-title>
      <v-card-subtitle>
        <span
        >{{ $t("users.dialogs.profileChanges.date") }}:
          {{ getDate(user.profileChanges.dateOfRequest) }}</span
        >
      </v-card-subtitle>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="5">
              <p>{{ $t("users.dialogs.profileChanges.subtitleUpdate") }}</p>
              <label for="name">
                {{ $t("users.dialogs.profileChanges.name") }}
              </label>
              <v-text-field
                :value="user.profileChanges.name"
                id="name"
                outlined
                dense
                readonly
              ></v-text-field>
              <label for="surname">
                {{ $t("users.dialogs.profileChanges.surname") }}
              </label>
              <v-text-field
                :value="user.profileChanges.surname"
                id="surname"
                outlined
                dense
                readonly
              ></v-text-field>
              <label for="phone">
                {{ $t("users.dialogs.profileChanges.phone") }}</label>
              <v-text-field
                :value="user.profileChanges.phone"
                id="phone"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="5" offset="2">
              <p>{{ $t("users.dialogs.profileChanges.subtitle") }}</p>
              <label for="name">
                {{ $t("users.dialogs.profileChanges.name") }}
              </label>
              <v-text-field
                :value="user.name"
                id="name"
                outlined
                dense
                disabled
              ></v-text-field>
              <label for="surname">
                {{ $t("users.dialogs.profileChanges.surname") }}
              </label>
              <v-text-field
                :value="user.surname"
                id="surname"
                outlined
                dense
                disabled
              ></v-text-field>
              <label for="phone">
                {{ $t("users.dialogs.profileChanges.phone") }}</label>
              <v-text-field
                :value="user.phone"
                id="phone"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex">
              <v-btn
                text
                @click="cancel"
              >
                {{ $t("common.cancel") }}
              </v-btn>
              <v-spacer/>
              <v-btn
                outlined
                class="mr-2"
                :disabled="loading"
                @click="reject()"
              >
                {{ $t("users.dialogs.profileChanges.reject") }}
              </v-btn>
              <v-btn
                color="primary"
                :loading="loading"
                :disabled="loading"
                @click="approve()"
              >
                {{ $t("users.dialogs.profileChanges.approve") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { dateFormat } from '@/utils/helpers'

export default {
  name: 'ProfileChangesDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
    getDate (date) {
      return dateFormat(date)
    },
    cancel () {
      this.$emit('cancel')
    },
    reject () {
      this.$emit('reject')
    },
    approve () {
      this.$emit('approve')
    }
  }
}
</script>
