<template>
  <div>
    <v-card class="pa-5">
      <div class="d-flex align-center mb-6">
        <h2 class="text-subtitle-1 font-weight-bold mr-4">
          {{ $t('impersonation.users') }}
        </h2>
        <div>
          <SearchInput v-model="searchInputValue" />
        </div>
          <v-row no-gutters>
          <v-col cols="2" md="2">
              <v-select
                v-model="selectedStatuses"
                multiple
                :items="availableValues()"
                item-text="description"
                item-value="id"
              >
              </v-select>
          </v-col>
        </v-row>
      </div>
      <data-table
        ref="dataTable"
        :headers="headers"
        endpoint="/Impersonation/GetUsers"
        :additionalParams="{ status: selectedStatuses}"
        serverPagination
        :search="searchInputValue"
        :item-class="itemRowBackground"
      >
        <template v-slot:[`item.fullName`]="{ item }">
          <div class="d-flex align-center">
            <StatusAvatar
              :userId="item.id"
              showStatus
              class="mr-2"
            ></StatusAvatar>
            <span>{{ item.fullName }}</span>
          </div>
        </template>
        <template v-slot:[`item.chatIcon`]="{ item }">
          <v-btn icon @click="startConversationWithUser(item.id)">
            <v-icon class="w-50" color="info">mdi-chat-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.status="{ item }">
          <div v-if="item.profileChanges">
            {{ $t("users.update") }}
          </div>
          <div v-else>
            {{ item.status | getDescription($store.getters.enums.userStatus) }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="!userCommonData.allowBackToAdminPanel && $permission('AccountImpresonification')"
            color="primary"
            outlined
            :disabled="loading || item.status === 2"
            @click="changeToUser(item.id)"
          >
            {{ $t('impersonation.switch') }}
          </v-btn>
        </template>
        <template v-slot:item.menu="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="item.profileChanges"
                link
                @click="openVerifyDialog(item)"
              >
                <v-list-item-title>
                  {{ $t("users.verifyProfile") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="editDataClick(item)"
              >
                <v-list-item-title>
                  {{ $t("users.editData") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="openResetPasswordDialog(item)"
              >
                <v-list-item-title>
                  {{ $t("users.resetPassword") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="block(item)" v-if="(item.id !== $store.getters.userCommonData.id || item.role !== adminRoleId) && item.status !== 2">
                <v-list-item-title>{{
                  $t("users.blockUser")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="activate(item.id)" v-if="(item.id !== $store.getters.userCommonData.id || item.role !== adminRoleId) && item.status === 3">
                <v-list-item-title>{{
                  $t("users.active")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="unblock(item)" v-if="(item.id !== $store.getters.userCommonData.id || item.role !== adminRoleId) && item.status === 2">
                <v-list-item-title>{{
                  $t("users.unblockUser")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @close="closeIntegrationApiTokenDialog"
                @click="openIntegrationApiTokenDialog(item)"
              >
                <v-list-item-title>
                  {{ $t("apiToken.userTokenOptionsBtn") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getUsers">{{
              $t("users.slotNoData")
            }}</v-btn>
        </template>
      </data-table>
    </v-card>

    <ProfileChangesDialog
      v-if="verifyDialog"
      v-model="verifyDialog"
      :user="selectedUser"
      :loading="loading"
      @cancel="verifyDialogCancel"
      @approve="verifyDialogApprove"
      @reject="verifyDialogReject"
    />
    <block-user
      v-if="blockDialog"
      :dialog="blockDialog"
      :user="selectedUser"
      :loading="loadingDialog"
      @close="blockDialogClose"
      @submit="blockDialogSubmit"
    ></block-user>
    <unblock-user
      v-if="unblockDialog"
      :dialog="unblockDialog"
      :user="selectedUser"
      :loading="loadingDialog"
      @cancel="unblockDialogClose"
      @unblock="unblockDialogSubmit"
    ></unblock-user>
    <reset-password
      v-if="resetPasswordDialog"
      :dialog="resetPasswordDialog"
      :user="selectedUser"
      :loading="loading"
      @cancel="resetPasswordDialogCancel"
      @reset="resetPasswrod"
    />
    <IntegrationApiTokenDialog
      :user="selectedUser"
      :isOpen="isIntegrationApiTokenDialogOpen"
      @close="isIntegrationApiTokenDialogOpen=false"
    />
      <Confirm ref="confirm" />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '@/services'
import SearchInput from '@/components/search/SearchInput.vue'
import { startConversation, start, stop } from '@/signalr/chat'
import StatusAvatar from '@/components/StatusAvatar'
import { notificationsStart, notificationsStop } from '@/signalr/notifications'
import { usersStart, usersStop } from '@/signalr/users'
import store from '@/store'
import ResetPassword from '../../components/dialogs/ResetUserPassword.vue'
import IntegrationApiTokenDialog from '@/views/auth/dialogs/IntegrationApiTokenDialog.vue'
import ProfileChangesDialog from '@/views/Companies/Market/CompanyDetails/Tabs/Dialogs/ProfileChangesDialog'
import BlockUser from '../Companies/CustomerService/CompaniesDetails/Tabs/Dialogs/BlockUser.vue'
import UnblockUser from '../Companies/CustomerService/CompaniesDetails/Tabs/Dialogs/UnblockUser.vue'
import { adminRoleId } from '@/utils/helpers'
import Confirm from '../../components/dialogs/Confirm.vue'

export default {
  name: 'Impersonation',
  components: {
    Confirm,
    SearchInput,
    StatusAvatar,
    ProfileChangesDialog,
    ResetPassword,
    BlockUser,
    UnblockUser,
    IntegrationApiTokenDialog
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('impersonation.users'),
          value: 'fullName'
        },
        {
          text: this.$t('impersonation.userId'),
          value: 'displayId'
        },
        {
          text: this.$t('impersonation.email'),
          value: 'email'
        },
        {
          text: this.$t('impersonation.phone'),
          value: 'phone'
        },
        {
          text: '',
          value: 'chatIcon'
        },
        {
          text: this.$t('users.state'),
          value: 'status'
        },
        {
          text: '',
          value: 'actions',
          align: 'end'
        },
        {
          text: '',
          align: 'end',
          width: '75',
          value: 'menu',
          sortable: false
        }
      ],
      searchInputValue: '',
      loading: false,
      selectedUser: null,
      verifyDialog: false,
      resetPasswordDialog: false,
      blockDialog: false,
      unblockDialog: false,
      loadingDialog: false,
      isIntegrationApiTokenDialogOpen: false,
      adminRoleId: null,
      selectedStatuses: []
    }
  },
  created () {
    this.adminRoleId = adminRoleId
  },
  computed: {
    ...mapGetters({
      userCommonData: 'userCommonData'
    })
  },
  methods: {
    async activate (id) {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('users.deactivateTitle'), this.$t('users.deactivateDesc'))) {
        try {
          await Service.post('CompanyUserManagement/Activate', {
            userId: id
          })
          await this.$refs.dataTable.getData()
          await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        } catch (e) {}
      }
      this.loading = false
    },
    closeIntegrationApiTokenDialog () {
      this.isIntegrationApiTokenDialogOpen = false
    },
    openIntegrationApiTokenDialog (user) {
      this.selectedUser = user
      this.isIntegrationApiTokenDialogOpen = true
    },
    itemRowBackground (item) {
      return item.profileChanges ? 'bgNew' : ''
    },
    async changeToUser (userId) {
      this.loading = true
      try {
        await usersStop()
        await notificationsStop()
        stop()
        const { data } = await Service.post('/Impersonation/ChangeToUser', {
          userId
        })
        if (!data.requiredAgreementsAccepted) {
          await store.dispatch('setInfoNotification', this.$t('impersonation.userDontAgreements'))
          start()
          await usersStart()
          await notificationsStart()
          return
        }
        localStorage.setItem('token', data.token)
        await this.$store.dispatch('fetchUserData')
        await this.$store.dispatch('chat/clearConversations')
        start()
        await usersStart()
        await notificationsStart()
        await this.$router.push({ name: 'Home' })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    startConversationWithUser (userId) {
      startConversation(userId)
    },
    async openVerifyDialog (user) {
      this.selectedUser = user
      this.verifyDialog = true
    },
    verifyDialogCancel () {
      this.verifyDialog = false
    },
    async verifyDialogApprove () {
      this.loading = true

      try {
        await Service.put('/CompanyUserManagement/ApproveUserChanges', {
          userId: this.selectedUser.id
        })

        this.verifyDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.approve')
        )

        await this.$refs.dataTable.getData()
      } catch (e) {}
      this.loading = false
    },
    async verifyDialogReject () {
      this.loading = true

      try {
        await Service.post('/CompanyUserManagement/RejectUserChanges', {
          userId: this.selectedUser.id
        })

        this.verifyDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.reject')
        )
        await this.$refs.dataTable.getData()
      } catch (e) {}
      this.loading = false
    },
    openResetPasswordDialog (user) {
      this.selectedUser = user
      this.resetPasswordDialog = true
      console.log(this.selectedStatuses)
    },
    resetPasswordDialogCancel () {
      this.resetPasswordDialog = false
    },
    async resetPasswrod () {
      this.loading = true
      try {
        await Service.put('/UserManagement/EnforcePasswordReset', {
          userId: this.selectedUser.id
        })
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.reset')
        )
        this.resetPasswordDialog = false
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    block (user) {
      this.selectedUser = user
      this.blockDialog = true
    },
    blockDialogClose () {
      this.blockDialog = false
    },
    async blockDialogSubmit (reason) {
      this.loadingDialog = true
      try {
        await Service.post('/CompanyUserManagement/Block', {
          userId: this.selectedUser.id,
          blockReason: reason
        })
        this.blockDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.blockSubmit')
        )
        await this.$refs.dataTable.getData()
      } catch (e) {}
      this.loadingDialog = false
    },
    unblock (user) {
      this.selectedUser = user
      this.unblockDialog = true
    },
    unblockDialogClose () {
      this.unblockDialog = false
    },
    async unblockDialogSubmit () {
      this.loadingDialog = true
      try {
        await Service.post('/CompanyUserManagement/Unblock', {
          userId: this.selectedUser.id
        })
        this.unblockDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.unblockSubmit')
        )
        await this.$refs.dataTable.getData()
      } catch (e) {}
      this.loadingDialog = false
    },
    editDataClick (user) {
      this.$router.push({ name: 'UserProfile', params: { userId: user.id } })
    },
    availableValues () {
      return this.$store.getters.enums.userStatus
        .map((el) => el)
    },
    getStatusesFilter () {
      let text = ''
      this.selectedStatuses.forEach((element, index) => {
        if (index === 0) {
          text = element
        } else {
          text = text + '&status[]=' + element
        }
      })
      return text
    }
  }
}
</script>
